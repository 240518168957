import { createMedia } from '@artsy/fresnel'
import PropTypes from 'prop-types'
import React, { Component, useState, useRef } from 'react'
import { InView } from 'react-intersection-observer'
import {
    Button,
    Container,
    Divider,
    Grid,
    Header,
    Icon,
    Image,
    List,
    Menu,
    Segment,
    Sidebar,
    Form,
    FormField,
    Input,
    Select,
    TextArea,
    MessageHeader,
    MessageContent,
    Message
} from 'semantic-ui-react'
import emailjs from '@emailjs/browser'
import './App.css'

import containerBackground from './assets/images/businessmeeting.webp'
import logo from './assets/logo/logo_transparent_background.png'

const { MediaContextProvider, Media } = createMedia({
    breakpoints: {
        mobile: 0,
        tablet: 768,
        computer: 1024,
    },
})

/* Heads up!
 * HomepageHeading uses inline styling, however it's not the best practice. Use CSS or styled
 * components for such things.
 */
const HomepageHeading = ({ mobile, scrollToSection }) => (
    <Container text>
        <Header
            as='h1'
            inverted
            content='NetSuite Expertise'
            style={{
                fontSize: mobile ? '2.5em' : '4em',
                color: 'red',
                fontWeight: 'bold',
                marginBottom: 0,
                marginTop: mobile ? '1.5em' : '4.5em',
                opacity: '1'
            }}
        />
        <Header
            as='h2'
            content='Empowering Business with Tailored NetSuite Solutions'
            inverted
            style={{
                fontSize: mobile ? '1.5em' : '1.7em',
                fontWeight: 'normal',
                marginTop: mobile ? '0.5em' : '1.75em',
                marginBottom: '1.75em',
            }}
        />
        <Button size='huge' color='red' onClick={() => scrollToSection('contactUsSegment')}>
            Get Started
            <Icon name='right arrow' />
        </Button>
    </Container>
)

HomepageHeading.propTypes = {
    mobile: PropTypes.bool,
    scrollToSection: PropTypes.func
}

/* Heads up!
 * Neither Semantic UI nor Semantic UI React offer a responsive navbar, however, it can be implemented easily.
 * It can be more complicated, but you can create really flexible markup.
 */
class DesktopContainer extends Component {
    state = {}

    toggleFixedMenu = (inView) => this.setState({ fixed: !inView })

    render() {
        const { children, scrollToSection } = this.props
        const { fixed } = this.state

        return (
            <Media greaterThan='mobile'>
                <InView onChange={this.toggleFixedMenu}>
                    <Segment
                        inverted
                        textAlign='center'
                        style={{
                            minHeight: 700,
                            padding: '1em 0em',
                            backgroundImage: `url(${containerBackground})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center'
                        }}
                        vertical
                    >
                        <Menu
                            fixed={fixed ? 'top' : null}
                            inverted={!fixed}
                            pointing={!fixed}
                            secondary={!fixed}
                            size='large'
                            borderless
                        >
                            <Container>
                                <Menu.Item as='a' position='left' onClick={() => scrollToSection()}>
                                    <img
                                        src={logo}
                                        alt='logo'
                                        style={{height: '75px', width: '180px'}}
                                    />
                                </Menu.Item>
                                <Menu.Menu position='right'>
                                    <Menu.Item as='div' onClick={() => scrollToSection('aboutUsSegment')}>
                                        <span className="headerLink">About Us</span>
                                    </Menu.Item>
                                    <Menu.Item as='div' onClick={() => scrollToSection('ourServicesSegment')}>
                                        <span className="headerLink">Our Services</span>
                                    </Menu.Item>
                                    <Menu.Item as='div' onClick={() => scrollToSection('caseStudiesSegment')}>
                                        <span className="headerLink">Case Studies</span>
                                    </Menu.Item>
                                    <Menu.Item as='div' onClick={() => scrollToSection('contactUsSegment')}>
                                        <span className="headerLink">Contact Us</span>
                                    </Menu.Item>
                                </Menu.Menu>
                            </Container>
                        </Menu>
                        <HomepageHeading scrollToSection={scrollToSection} />
                    </Segment>
                </InView>

                {children}
            </Media>
        )
    }
}

DesktopContainer.propTypes = {
    children: PropTypes.node,
}

class MobileContainer extends Component {
    state = {}

    handleSidebarHide = () => this.setState({ sidebarOpened: false })

    handleToggle = () => this.setState({ sidebarOpened: true })

    render() {
        const { children, scrollToSection } = this.props
        const { sidebarOpened } = this.state

        return (
            <Media as={Sidebar.Pushable} at='mobile'>
                <Sidebar.Pushable>
                    <Sidebar
                        as={Menu}
                        animation='overlay'
                        inverted
                        onHide={this.handleSidebarHide}
                        vertical
                        visible={sidebarOpened}
                    >
                        <Menu.Item as='a' onClick={() => scrollToSection()}>
                            <img
                                src={logo}
                                alt='logo'
                                style={{height: '75px', width: '180px'}}
                            />
                        </Menu.Item>
                        <Menu.Item as='a' position='right' onClick={() => scrollToSection('aboutUsSegment')}>About Us</Menu.Item>
                        <Menu.Item as='a' position='right' onClick={() => scrollToSection('ourServicesSegment')}>Our Services</Menu.Item>
                        <Menu.Item as='a' position='right' onClick={() => scrollToSection('caseStudiesSegment')}>Case Studies</Menu.Item>
                        <Menu.Item as='a' position='right' onClick={() => scrollToSection('contactUsSegment')}>Contact Us</Menu.Item>
                    </Sidebar>

                    <Sidebar.Pusher dimmed={sidebarOpened}>
                        <Segment
                            inverted
                            textAlign='center'
                            style={{
                                minHeight: 350,
                                padding: '1em 0em',
                                backgroundImage: `url(${containerBackground})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center'
                            }}
                            vertical
                        >
                            <Container>
                                <Menu inverted pointing secondary size='large'>
                                    <Menu.Item onClick={this.handleToggle}>
                                        <Icon name='sidebar' />
                                    </Menu.Item>
                                    <Menu.Item as='a' onClick={() => scrollToSection()}>
                                        <img src={logo} alt='logo' style={{height: '75px', width: '180px'}}/>
                                    </Menu.Item>
                                </Menu>
                            </Container>
                            <HomepageHeading mobile scrollToSection={scrollToSection} />
                        </Segment>

                        {children}
                    </Sidebar.Pusher>
                </Sidebar.Pushable>
            </Media>
        )
    }
}

MobileContainer.propTypes = {
    children: PropTypes.node,
}

const ResponsiveContainer = ({ children, scrollToSection }) => (
    /* Heads up!
	 * For large applications it may not be best option to put all page into these containers at
	 * they will be rendered twice for SSR.
	 */
    <MediaContextProvider>
        <DesktopContainer scrollToSection={scrollToSection}>{children}</DesktopContainer>
        <MobileContainer scrollToSection={scrollToSection}>{children}</MobileContainer>
    </MediaContextProvider>
)

ResponsiveContainer.propTypes = {
    children: PropTypes.node,
}

const App = () => {
    // State for form visibility and validation
    const [showForm, setShowForm] = useState(true);
    const [showResponse, setShowResponse] = useState(false);
    const [formValues, setFormValues] = useState({
        name: '',
        email: '',
        type: '',
        message: ''
    });

    // Form button state
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [buttonLoading, setButtonLoading] = useState(false);

    // Reference to store the values so we can validate them
    const nameRef = useRef();
    const validNameRef = useRef();
    const emailRef = useRef();
    const validEmailRef = useRef();
    const typeRef = useRef();
    const validTypeRef = useRef();
    const messageRef = useRef();
    const validMessageRef = useRef();

    // Function to scroll to a section
    const scrollToSection = (ref) => {
        // console.log('scrollToSection', ref);
        switch(ref) {
            case 'contactUsSegment':
                const contactUs = document.getElementById('contactUsSegment');
                contactUs?.scrollIntoView({ behavior: 'smooth' });
                break;
            case 'aboutUsSegment':
                const aboutUs = document.getElementById('aboutUsSegment');
                aboutUs?.scrollIntoView({ behavior: 'smooth' });
                break;
            case 'ourServicesSegment':
                const ourServices = document.getElementById('ourServicesSegment');
                ourServices?.scrollIntoView({ behavior: 'smooth' });
                break;
            case 'caseStudiesSegment':
                const caseStudies = document.getElementById('caseStudiesSegment');
                caseStudies?.scrollIntoView({ behavior: 'smooth' });
                break;
            default:
                // Smoothly scroll to top of page
                window.scrollTo({ top: 0, behavior: 'smooth' });
                break;
        }
    }

    // Function to validate the form
    const validateForm = () => {
        const fieldValidationObj = {
            name: validNameRef.current,
            email: validEmailRef.current,
            type: validTypeRef.current,
            message: validMessageRef.current
        }
        // console.log('validateForm fieldValidationObj', fieldValidationObj);
        const isValid = Object.values(fieldValidationObj).every((value) => value);
        // console.log('validateForm isValid', isValid);

        // Set the button disabled state
        setButtonDisabled(!isValid);
    }

    // Function to validate a field
    const validateField = (field, value) => {
        // console.log('validateField field', field, value);
        // console.log('validateField fieldValidation', field, {
        //     name: validNameRef.current,
        //     email: validEmailRef.current,
        //     type: validTypeRef.current,
        //     message: validMessageRef.current
        // });
        // console.log('validateField formValues', field, {
        //     name: nameRef.current,
        //     email: emailRef.current,
        //     type: typeRef.current,
        //     message: messageRef.current
        // });
        switch(field) {
            case 'name':
            case 'message':
                // Set the value to state
                setFormValues({...formValues, [field]: value});
                if (field === 'name') {
                    nameRef.current = value;
                    validNameRef.current = value.length > 0;
                } else {
                    messageRef.current = value;
                    validMessageRef.current = value.length > 0;
                }

                break;
            case 'email':
                // Set the value to state
                setFormValues({...formValues, email: value});
                emailRef.current = value;

                // Validate the email
                const emailValidation = emailRef.current.match(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                );
                // console.log('validateField emailValidation', emailValidation);
                validEmailRef.current = emailValidation && emailValidation.length > 0;

                break;
            case 'type':
                // Set the value to state
                setFormValues({...formValues, type: value.toLowerCase()});
                typeRef.current = value.toLowerCase();

                // Validate the type
                validTypeRef.current = typeRef.current.length > 0;

                break;
            default:
                break;
        }

        // Validate the form
        validateForm();
    }

    // Function to send the contact us email
    const sendContactUsEmail = (event) => {
        event.preventDefault();
        setButtonLoading(true);
        // console.log('sendContactUsEmail event', event.target);

        // Form values
        const templateParams = {
            name: nameRef.current,
            email: emailRef.current,
            type: typeRef.current,
            message: messageRef.current,
            toname: 'JPCW'
        }

        emailjs.send('jpcw_contact', 'jpcw_contact', templateParams, 'olK3_v7TseAvHUtzG').then((result) => {
            // console.log('sendContactUsEmail result', result);
            setShowForm(false);
            setShowResponse(true);
        }).catch((error) => {
            console.log('sendContactUsEmail error', error);
            setShowForm(true);
            setShowResponse(false);
        }).finally(() => {
            setButtonLoading(false);
        });
    }

    return (
        <ResponsiveContainer scrollToSection={scrollToSection}>
            <Segment style={{padding: '4em 0em'}} vertical id="aboutUsSegment">
                <Grid container stackable verticalAlign='middle'>
                    <Grid.Row>
                        <Grid.Column width={8}>
                            <Header as='h3' style={{fontSize: '2em'}}>
                                NetSuite Expertise
                            </Header>
                            <p style={{fontSize: '1.33em'}}>
                                JP Custom Works specializes in NetSuite consulting and development. We leverage our deep
                                industry knowledge and technical expertise to help businesses optimize their NetSuite ERP
                                system. Whether you're looking to implement NetSuite for the first time, customize your
                                existing system, or need ongoing support, we're here to help. Our services include system
                                implementation, customization, integration, training, and support. We're committed to
                                helping you maximize your NetSuite investment and achieve your business goals.
                            </p>
                        </Grid.Column>
                        <Grid.Column floated='right' width={6}>
                            <Image bordered rounded size='large' src={require('./assets/images/NetSuiteConsultant.jpg')}/>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column textAlign='center'>
                            <Button
                                size='huge'
                                color='red'
                                onClick={() => scrollToSection('contactUsSegment')}
                            >
                                Let's Get to Work!
                            </Button>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>

            <Segment style={{padding: '0em'}} vertical id="ourServicesSegment">
                <Grid columns='equal' stackable>
                    <Grid.Row textAlign='left'>
                        <Grid.Column style={{padding: '5em'}}>
                            <Image bordered rounded style={{
                                width: '450px',
                                height: '253px',
                                objectFit: 'cover'
                            }}
                                   src={require('./assets/images/group-business-specialist-analysis-trading-candles_677805-2004.avif')}/>
                            <Header as='h3' style={{fontSize: '2em'}}>
                                NetSuite Consulting
                            </Header>
                            <p style={{fontSize: '1.33em'}}>Our NetSuite Consulting service is designed to help businesses
                                optimize their operations. We provide expert advice and strategies tailored to your specific
                                needs.</p>
                        </Grid.Column>
                        <Grid.Column style={{padding: '5em'}}>
                            <Image bordered rounded size='large'
                                   src={require('./assets/images/private-investigators-duty-police-section-working-together-solve-crime-uncover-hidden-case-details-forensic-experts-gathering-intelligence-from-surveillance-photos-records_482257-64960.avif')}/>
                            <Header as='h3' style={{fontSize: '2em'}}>
                                NetSuite Development
                            </Header>
                            <p style={{fontSize: '1.33em'}}>Our NetSuite Development service offers custom solutions to
                                enhance your business processes. Using development best practices, we build in-Suite,
                                hybrid, and external applications that are robust, scalable, and perfectly aligned with your
                                business goals to help you get the most out of your NetSuite environment.</p>
                        </Grid.Column>
                        <Grid.Column style={{padding: '5em'}}>
                            <Image bordered rounded size='large'
                                   src={require('./assets/images/compliance-system-modish-online-corporate-business_31965-81090.avif')}/>
                            <Header as='h3' style={{fontSize: '2em'}}>
                                NetSuite Integration
                            </Header>
                            <p style={{fontSize: '1.33em'}}>Our NetSuite Integration service ensures seamless connectivity
                                between your business applications. We focus on improving data flow and reducing operational
                                inefficiencies.</p>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>

            <Segment style={{padding: '0em'}} vertical>
                <Grid celled='internally' columns='equal' stackable>
                    <Grid.Row textAlign='center'>
                        <Grid.Column style={{padding: '5em'}} color={'black'} inverted>
                            <Header as='h2' style={{fontSize: '3em', color: 'red'}}>
                                Ready to get started?
                            </Header>
                            <p style={{fontSize: '1.33em'}}>Reach out now for an expert NetSuite consultation. Need help
                                with NetSuite? We're ready to assist you.<br/>Don't hesitate, contact us now for top-notch
                                NetSuite consulting and development services!</p>
                            <Button size='huge' color='red' onClick={() => scrollToSection('contactUsSegment')}>Contact Us</Button>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>

            <Segment style={{padding: '6em 0em'}} vertical id="caseStudiesSegment">
                <Container text>
                    <Divider
                        as='h4'
                        className='header'
                        horizontal
                        style={{textTransform: 'uppercase'}}
                    >
                        Case Studies
                    </Divider>

                    <Header as='h3' style={{fontSize: '1.5em'}}>
                        Custom Approvals
                    </Header>
                    <p style={{fontSize: '1em'}}>
                        Utilizing a combination of custom records, SuiteFlow, and SuiteScript, we can customize your
                        approval processes to match your company structure and rules. Approve by department or location,
                        across classes and at the transaction line level. Make sure approvals still get covered when
                        approvers are out. Approve in NetSuite or via Email. We can help you streamline your approval
                        processes and reduce the time it takes to get things done.
                    </p>
                    <Button as='a' size='large' onClick={() => scrollToSection('contactUsSegment')}>
                        Ask Us How
                    </Button>

                    <Header as='h3' style={{fontSize: '1.5em', marginTop: '2em'}}>
                        Rethinking Restocking
                    </Header>
                    <p style={{fontSize: '1em'}}>
                        Take advantage of a hybrid solution to Restock your shelves. Using custom details per item and
                        location, and a barcode-enabled device, simply walk your shelves, scan a product, count the on-hand,
                        and our Restocking App determines how much to reorder. Uploads are created as Purchase Requisitions
                        in NetSuite to allow your Purchasing department to decide who the right vendor is and at what cost
                        - <i>not</i> the folks walking the warehouse.
                    </p>
                    <Button as='a' size='large' onClick={() => scrollToSection('contactUsSegment')}>
                        Request a Demo
                    </Button>

                    <Header as='h3' style={{fontSize: '1.5em', marginTop: '2em'}}>
                        Smart Requisitions
                    </Header>
                    <p style={{fontSize: '1em'}}>
                        Follow up on our Restocking App with Smart Requisitions. The native NetSuite process of Requisitions
                        forces you to create a Purchase Order for every Requisition line. But what if you already have
                        inventory in another location? Why not transfer inventory instead? With Smart Requisitions, we can
                        help you determine the best way to fulfill your needs, whether it's a Purchase Order, a Transfer
                        Order, or closing the request without any action. Take better control of your inventory and reduce
                        your purchase costs with Smart Requisitions.
                    </p>
                    <Button as='a' size='large' onClick={() => scrollToSection('contactUsSegment')}>
                        See How it Works
                    </Button>
                </Container>
            </Segment>

            <Segment style={{padding: '0em 0em 3em'}} secondary vertical>
                <Grid columns={2} stackable>
                    <Grid.Row textAlign='center'>
                        <Grid.Column
                            color={'black'}
                            inverted width={6}
                            floated={'right'}
                            style={{textAlign: 'right'}}
                        >
                            <Image
                                size='big'
                                rounded
                                style={{
                                    marginTop: '-30px',
                                    marginBottom: '-30px',
                                    display: 'inline-block',
                                }}
                                src={require('./assets/images/close-up-workplace-with-glowing-dna-hologram-laptop-other-items-blurry-background-bioengineering-technology-concept-3d-rendering_670147-28213.avif')}
                            />
                        </Grid.Column>
                        <Grid.Column style={{padding: '5em', textAlign: 'left'}} color={'black'} inverted width={10}>
                            <Header as='h2' style={{fontSize: '3em', color: 'red'}}>
                                Our Mission
                            </Header>
                            <p style={{fontSize: '1.33em'}}>Our mission is to empower businesses with innovative NetSuite solutions, delivering exceptional consulting and development services to drive growth, efficiency, and success.</p>
                            <Button size='huge' color='red' onClick={() => scrollToSection('contactUsSegment')}>Contact Us</Button>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>

            <Segment style={{padding: '0em'}} secondary vertical id="contactUsSegment">
                <Grid centered columns='equal' >
                    <Grid.Row textAlign='center'>
                        <Grid.Column style={{padding: '3em 5em'}} computer={6} mobile={16}>
                            {showForm ? null : showResponse ? <Message positive icon><Icon name='connectdevelop' /><MessageContent><MessageHeader>Thanks for reaching out!</MessageHeader><p>We'll be contacting you soon!</p></MessageContent></Message> : null}
                            {showResponse ? null : showForm ?
                                <Form
                                    name="jpcw_contact"
                                    id="jpcw_contact"
                                    data-netlify="true"
                                >
                                    <input type="hidden" name="form-name" value="jpcw_contact"/>
                                    <input type="hidden" name="toname" value="JPCW"/>
                                    <input type="hidden" name="replyto" value="JPCW"/>
                                    <Header as='h3' style={{fontSize: '3em'}}>
                                        Contact Us

                                    </Header>
                                    <p style={{fontSize: '1.33em'}}>Got questions? Reach out for expert NetSuite
                                        Solutions!</p>

                                    <FormField error={validNameRef.current === false}>
                                        <label htmlFor="name" style={{textAlign: 'left'}}>Name</label>
                                        <Input
                                            type="text"
                                            name="name"
                                            id="name"
                                            placeholder="Your Name"
                                            autoComplete="name"
                                            value={formValues.name}
                                            onChange={(e) => validateField('name', e.target.value)}
                                        />
                                    </FormField>
                                    <FormField error={validEmailRef.current === false || validEmailRef.current === null}>
                                        <label htmlFor="email" style={{textAlign: 'left'}}>Email</label>
                                        <Input
                                            type="email"
                                            name="email"
                                            id="email"
                                            placeholder="Your Email"
                                            autoComplete="email"
                                            value={formValues.email}
                                            onChange={(e) => validateField('email', e.target.value)}
                                        />
                                    </FormField>
                                    <FormField error={validTypeRef.current === false}>
                                        <label htmlFor="type" style={{textAlign: 'left'}}>Area of Interest</label>
                                        <Select
                                            name="type"
                                            id="type"
                                            placeholder="Select your interest"
                                            options={[
                                                {key: 'consulting', text: 'Consulting', value: 'consulting'},
                                                {key: 'development', text: 'Development', value: 'development'},
                                                {key: 'integration', text: 'Integration', value: 'integration'},
                                                {key: 'demo', text: 'Product Demo', value: 'product demo'}
                                            ]}
                                            value={formValues.type}
                                            onChange={(e) => validateField('type', e.target.textContent)}
                                        />
                                    </FormField>
                                    <FormField error={validMessageRef.current === false}>
                                        <label htmlFor="message" style={{textAlign: 'left'}}>Message</label>
                                        <TextArea
                                            name="message"
                                            id="message"
                                            placeholder="What can we help you with?"
                                            value={formValues.message}
                                            rows={4}
                                            onChange={(e) => validateField('message', e.target.value)}
                                        />
                                    </FormField>
                                    <Button
                                        size='large'
                                        color='red'
                                        type="submit"
                                        animated='fade'
                                        disabled={buttonDisabled}
                                        loading={buttonLoading}
                                        onClick={(e) => sendContactUsEmail(e)}
                                        onKeyPress={(e) => e.key === 'Enter' || e.key === 'Space' ? sendContactUsEmail(e) : null}
                                    >
                                        <Button.Content visible>Send</Button.Content>
                                        <Button.Content hidden><Icon name='right arrow'/></Button.Content>
                                    </Button>
                                </Form>
                                : null
                            }
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>

            <Segment inverted vertical style={{padding: '5em 0em'}}>
                <Container>
                    <Grid divided inverted stackable>
                        <Grid.Row>
                            <Grid.Column width={3}>
                                <Header inverted as='h4' content='About'/>
                                <List link inverted>
                                    <List.Item as='a' onClick={() => scrollToSection('aboutUsSegment')}>About Us</List.Item>
                                    <List.Item as='a' onClick={() => scrollToSection('ourServicesSegment')}>Our Services</List.Item>
                                    <List.Item as='a' onClick={() => scrollToSection('caseStudiesSegment')}>Case Studies</List.Item>
                                    <List.Item as='a' onClick={() => scrollToSection('contactUsSegment')}>Contact Us</List.Item>
                                </List>
                            </Grid.Column>
                            <Grid.Column width={3}>
                                <Header inverted as='h4' content='Services'/>
                                <List link inverted>
                                    <List.Item as='a' onClick={() => scrollToSection('ourServicesSegment')}>NetSuite Consulting</List.Item>
                                    <List.Item as='a' onClick={() => scrollToSection('ourServicesSegment')}>NetSuite Development</List.Item>
                                    <List.Item as='a' onClick={() => scrollToSection('ourServicesSegment')}>NetSuite Integrations</List.Item>
                                    <List.Item as='a' onClick={() => scrollToSection('ourServicesSegment')}>Website / Mobile App Development</List.Item>
                                </List>
                            </Grid.Column>
                            <Grid.Column width={7}>
                                <Header as='h4' inverted>
                                    Contact
                                </Header>
                                <p>
                                    Submit the Contact Us form or reach out on social media. We're here to help you with
                                    your NetSuite needs.
                                </p>
                                <Icon name='linkedin square' size='huge' link onClick={() => window.open('https://www.linkedin.com/company/jp-custom-works', '_blank')}/>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Container>
            </Segment>
        </ResponsiveContainer>
    )
}

export default App;
